<template>
  <div>
    <!-- tab切换 -->
    <div class="tabs_box">
      <div
        class="order_tabs"
        :class="{ active: active == index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="changeTabs(index)"
      >
        {{ item }}
        <div v-if="active == index" class="border"></div>
      </div>
    </div>
    <div class="order_list">
      <div class="list_box" v-for="(item, index) in dataList" :key="index">
        <div class="list_top flex1">
          <div class="topLeft flex1">
            <div class="bianhao">订单编号：{{item.orderid}}</div>
            <div class="time">{{item.create_at}}</div>
          </div>

          <!-- <div class="status" v-if="item.type==0">订单状态：待支付</div>
          <div class="status" v-if="item.type==1">订单状态：待发货</div>
          <div class="status" v-if="item.type==2">订单状态：待收货</div>
          <div class="status" v-if="item.type==3">订单状态：待评价</div>-->
          <div class="status" v-if="item.orderstate==0">订单状态：失效</div>
          <div class="status" v-if="item.orderstate==1">订单状态：已完成</div>
          <div class="status" v-if="item.orderstate==2">订单状态：待付款</div>
        </div>
        <div class="list_body">
          <div class="left-box">
            <div class="list_left" v-for="(item1, index1) in item.culum" :key="index1">
              <img v-if="item1.alias_litpic" :src="item1.alias_litpic" alt />
              <div class="info">
                <div class="name">{{item1.alias_name}}</div>
                <div>
                  <span>￥</span>
                  {{item1.alias_price}}
                </div>
              </div>
            </div>
          </div>
          <div class="list_rigth">
            <div class="price_box">
              <div class="yuanjia">
                原价：
                <s>￥{{item.total}}</s>
              </div>
              <!-- <div class="yuanjia">折扣：-￥{{item.discount}}</div> -->
              <div class="shifu">
                实付：
                <span>￥{{item.profit}}</span>
              </div>
            </div>
            <div class="btn">
              <!-- <div class="zhifu zhifu1" v-if="item.type==0">立即支付</div>
              <div class="zhifu zhifu1" v-if="item.type==1">确认收货</div>
              <div class="zhifu zhifu1" v-if="item.type==2">提醒发货</div>
              <div class="zhifu zhifu1" v-if="item.type==3">评价</div>-->
              <div class="zhifu zhifu1" @click="pay(item.id)" v-if="item.orderstate==2">立即支付</div>
              <!-- <div class="zhifu zhifu1" v-if="item.type==1">确认收货</div> -->
              <!-- <div class="zhifu zhifu1" v-if="item.orderstate!=2">删除订单</div> -->
              <!-- <div class="cancle" v-if="item.orderstate==2">取消订单</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { my_order } from "../../assets/js/personal";
export default {
  data() {
    return {
      imgUrl: "http://v2.hnzye.cn",
      active: 0,
      // tabsList: ["全部订单", "待付款", "待发货", "待收货", "待评价"],
      tabsList: ["全部订单", "待付款"],
      user_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
      siteId: window.localStorage.getItem("siteId"),
      status: "",
      //0待支付 1待发货 2待收货 3待评价
      dataList: [
        {
          id: 1,
          type: 0,
          num: "D1903041037521024",
          time: "2020-03-04  10:37:52",
          goods: [
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
          ],
          oldprice: "1200",
          discount: "400",
          allprice: "800",
        },
        {
          id: 2,
          type: 1,
          num: "D1903041037521024",
          time: "2020-03-04  10:37:52",
          goods: [
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
          ],
          oldprice: "1200",
          discount: "400",
          allprice: "800",
        },
        {
          id: 3,
          type: 2,
          num: "D1903041037521024",
          time: "2020-03-04  10:37:52",
          goods: [
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
          ],
          oldprice: "1200",
          discount: "400",
          allprice: "800",
        },
        {
          id: 4,
          type: 3,
          num: "D1903041037521024",
          time: "2020-03-04  10:37:52",
          goods: [
            {
              img: require("../../assets/img/personal/img_06.png"),
              title: "一建法规",
              price: 800,
            },
          ],
          oldprice: "1200",
          discount: "400",
          allprice: "800",
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    changeTabs(i) {
      this.active = i;
      if ((i = 1)) {
        this.status = 2;
      } else {
        this.status = "";
      }
      this.init()
    },
    init() {
      my_order({
        user_id: this.user_id,
        siteId: this.siteId,
        status: this.status,
      }).then((res) => {
        // console.log(res);
        this.dataList = res.data.list;
      });
    },
    pay(orderid){
      this.$router.push({path:'/payment',query:{order_id:orderid}})
    }
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.active {
  color: #0090f0;
  font-size: 18px;
  // font-weight: bold;
}

.tabs_box {
  display: flex;
  font-size: 16px;
  color: #656565;
  border-bottom: 1px solid #dddddd;
  // width: 900px;
  .order_tabs {
    height: 64px;
    line-height: 60px;
    // margin-right: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    .border {
      border-bottom: 4px solid #0090f0;
    }
  }
}
.order_list {
  width: 930px;
  padding: 25px;
  box-sizing: border-box;
  .list_box {
    width: 100%;
    // height: 233px;

    background: rgba(255, 255, 255, 1);
    border: 1px solid #f2f2f2;
    // box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .list_top {
      color: "#333333";
      height: 54px;
      border-bottom: 1px solid #f1f1f1;
      justify-content: space-between;
      .topLeft {
        .bianhao {
          font-size: 14px;

          margin-right: 68px;
        }
        .time {
          font-size: 12px;
        }
      }
    }
    .list_body {
      cursor: pointer;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      .list_left {
        display: flex;
        align-items: center;
        margin-top: 24px;
        img {
          width: 200px;
          height: 120px;
          border-radius: 6px;
          margin-right: 19px;
        }
        .info {
          div:nth-child(1) {
            font-size: 18px;
            color: #333;
            font-family: PingFang SC;
            font-weight: 500;
          }
          div:nth-child(2) {
            margin-top: 35px;
            font-size: 22px;
            color: #f13232;
            span {
              font-size: 14px;
            }
          }
        }
      }
      .list_rigth {
        display: flex;
        margin-top: 47px;
        // align-items: center;
        .price_box {
          margin-right: 80px;
          line-height: 1.8;
          .yuanjia {
            font-size: 14px;
            color: #999;
          }
          .shifu {
            font-size: 14px;
            color: #999;
            span {
              font-size: 18px;
              color: #f13232;
            }
          }
        }
        .btn {
          width: 120px;
          text-align: center;
          .zhifu {
            width: 120px;
            height: 40px;
            // background: rgba(241, 50, 50, 1);
            border-radius: 20px;
            font-size: 14px;
            color: #f13232;
            // color: #fff;
            line-height: 40px;
            margin-bottom: 15px;
            border: 1px solid #f13232;
            box-sizing: border-box;
            cursor: pointer;
          }
          .cancle {
            font-size: 14px;
            color: #333;
            cursor: pointer;
          }
        }
      }
    }
    &:hover {
      border: 1px solid #ccc;
      .name {
        color: #0090f0 !important;
      }
      .zhifu1 {
        background: rgba(241, 50, 50, 1);
        color: #fff !important;
      }
    }
  }
}
</style>